export function useDecal () {
  const telegramUrl = 'https://t.me/vell365'
  const kakaoTalkUrl = ''
  const foreverDomainUrl = `https://${import.meta.env.VITE_FOREVER_DOMAIN}`

  return {
    telegramUrl,
    kakaoTalkUrl,
    foreverDomainUrl,
  }
}
